import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

const NotFoundPage = ({ data, pageContext, ...props }) => {
  useEffect(() => {
    const allLangs = data.datoCmsSite.locales;
    const defaultLang = allLangs[0];
    const currentLang = pageContext.locale;

    const navLang = currentLang !== defaultLang ? `${currentLang}/` : '';

    if (props.location?.state?.redirected === undefined) {
      navigate(`/${navLang}404`, {
        replace: true,
        state: { redirected: true },
      });
    }
  }, []);

  return <div>404</div>;
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPageQuery($locale: String) {
    datoCmsSite {
      locale
      locales
    }
    seoData: datoCmsSite(locale: { eq: $locale }) {
      globalSeo {
        siteName
        fallbackSeo {
          description
          title
          image {
            fluid(maxWidth: 760, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    datoCmsHeader(locale: { eq: $locale }) {
      team {
        phone
        email
        photo {
          fluid(maxWidth: 100, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      menuText
      menu {
        link
        text
      }
    }
    datoCmsFooter(locale: { eq: $locale }) {
      locale
      reviewLabel
      reviewMedia {
        url
        alt
        fluid(maxWidth: 180, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      footerLinks {
        title
        link
      }
      reviewLink
    }
  }
`;
